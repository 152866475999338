<template>
  <div class="flex flex-col h-full justify-between" :class="settings ? 'pb-3' : 'pb-5'">
    <ion-list
      lines="none"
      class="cursor-pointer select-none p-5 pr-0 pt-3 pb-0"
      :class="settings ? 'max-h-[60vh] overflow-y-auto' : ''"
    >
      <ion-item @click="navigateToProfile" :class="{ primary: urlIsProfile }">
        <ion-avatar
          :class="{ primary: urlIsProfile }"
          class="avatar h-11 w-11 md:h-13 md:w-13"
        >
          <div class="h-10 w-10 md:h-12 md:w-12">
            <UserAvatar :avatar="avatar" />
          </div>
        </ion-avatar>
        <ion-label class="font-sf-pro font-bold text-menu leading-menu pl-3"
          >Profile</ion-label
        >
      </ion-item>
      <ion-item
        v-if="globalStore.isParler"
        @click="navigateToHome"
        :class="{ primary: urlIsHome }"
      >
        <ion-icon class="w-10 ion-padding-end" :src="home" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">Home</ion-label>
      </ion-item>
      <ion-item @click="navigateToSettings" :class="{ primary: urlIsSettings }">
        <ion-icon class="w-10 ion-padding-end" :icon="cog" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu"
          >Settings</ion-label
        >
      </ion-item>
      <ion-item @click="emit('openInvite')">
        <ion-icon class="w-10 ion-padding-end" :src="share" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">Invite</ion-label>
      </ion-item>
      <ion-item @click="navigateToConnections" :class="{ primary: urlIsConnections }">
        <ion-icon class="w-10 ion-padding-end" :src="connectionsIcon" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu"
          >Connections</ion-label
        >
      </ion-item>
      <!-- <ion-item v-if="globalStore.isParler" @click="openParlerStore">
          <ion-icon class="w-10 ion-padding-end" :src="parlerShop" />
          <ion-label class="font-sf-pro font-bold text-menu leading-menu">Shop</ion-label>
        </ion-item> -->
      <!-- <ion-item @click="openParlerNews">
        <ion-icon class="w-10 ion-padding-end" :src="parlerNews" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">News</ion-label>
      </ion-item> -->
      <ion-item @click="openParlerPay">
        <ion-icon class="w-10 ion-padding-end" :src="parlerPay" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">Wallet</ion-label>
      </ion-item>
      <ion-item @click="openPlayTv">
        <ion-icon class="w-10 ion-padding-end dark:hidden" :src="playTvLight" />
        <ion-icon class="w-10 ion-padding-end hidden dark:block" :src="playTvDark" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">PlayTv</ion-label>
      </ion-item>
      <ion-item
        v-if="new Date(globalStore.timeoutExpiresAt) > new Date()"
        @click="navigateToViolations"
        class="text-2xl font-bold text-menu leading-menu text-red-500"
        :class="!remainingTime && 'animate-pulse'"
      >
        <ion-icon class="w-10 ion-padding-end" :src="timeout" />
        <ion-label
          v-if="remainingTime"
          class="font-mono font-bold tracking-wide text-red-500 leading-none"
          >{{ remainingTime }}</ion-label
        >
        <ion-label v-else class="text-menu leading-menu">Timeout</ion-label>
      </ion-item>
    </ion-list>
    <ion-list lines="none" class="cursor-pointer select-none px-5 pb-0 overflow-y-auto">
      <ion-item @click="toggleSettings">
        <ion-icon
          :icon="!settings ? chevronUp : chevronDown"
          class="w-10 ion-padding-end"
        />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">{{
          !settings ? "More" : "Less"
        }}</ion-label>
      </ion-item>
      <ion-item
        v-if="hasViolations && settings && !remainingTime"
        @click="navigateToViolations"
        :class="{ primary: urlIsViolations }"
      >
        <ViolationIcon class="mx-2 w-10 ion-padding-end" :active="urlIsViolations" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu"
          >Violations</ion-label
        >
      </ion-item>
      <ion-item @click="emit('open')" v-if="settings">
        <FeedbackIcon class="mx-2 w-10 ion-padding-end" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu"
          >Feedback</ion-label
        >
      </ion-item>
      <ion-item
        v-if="(isCurrentUserAdmin || isCurrentUserEmployee) && settings"
        @click="loginAdmin"
      >
        <ion-icon class="w-10 ion-padding-end" :src="admin" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">Admin</ion-label>
      </ion-item>
      <ion-item @click="openKnowledgeCenter()" v-if="settings">
        <ion-icon class="w-10 ion-padding-end" :icon="helpCircleOutline" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu"
          >Help Center</ion-label
        >
      </ion-item>
      <ion-item class="ion-padding-end" :class="!dark_mode ? 'pl-2' : ''" v-if="settings">
        <ion-icon
          v-if="!dark_mode"
          class="w-5 ion-padding-end"
          :icon="moonSharp"
          :class="{
            white: dark_mode,
          }"
        />
        <ion-icon
          v-else
          class="w-10 ion-padding-end"
          :icon="sunnySharp"
          :class="{
            white: dark_mode,
          }"
        />
        <ion-toggle v-model="dark_mode" class="ml-2" />
      </ion-item>
      <ion-item @click="logout()" class="ion-padding-end ml-1" v-if="settings">
        <ion-icon class="ml-0 w-8 ion-padding-end" :icon="logOutSharp" />
        <ion-label class="font-sf-pro font-bold text-menu leading-menu">Logout</ion-label>
      </ion-item>
      <div
        class="flex items-center space-x-2 text-black dark:text-white"
        @click="forceRefresh"
        v-if="settings"
      >
        <ion-label class="text-xs">v.3.0.0.0-{{ date }}</ion-label>
        <ion-icon :icon="refresh" class="text-xl cursor-pointer" />
      </div>
      <p v-if="settings" class="text-[.6rem] text-black dark:text-white">
        {{ version }}
      </p>
    </ion-list>
  </div>
</template>

<script setup>
import { IonAvatar, IonIcon, IonItem, IonLabel, IonList, IonToggle } from "@ionic/vue";
import home from "@/assets/parler/home.svg";
import { useGlobalStore } from "../stores/global";
import connectionsIcon from "@/assets/connections.svg";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import UserAvatar from "./UserAvatar.vue";
import { useAdminStore } from "@/stores/admin";
import { useRoute, useRouter } from "vue-router";
import share from "@/assets/parler/share.svg";
import admin from "@/assets/admin.svg";
import timeout from "@/assets/parler/timeout.svg";
import parlerNews from "@/assets/parler_news.svg";
import playTvLight from "@/assets/parler/playtv_light.svg";
import playTvDark from "@/assets/parler/playtv_dark.svg";
import FeedbackIcon from "./tenantcomponents/parler/icons/FeedbackIcon.vue";
import ViolationIcon from "./tenantcomponents/parler/icons/ViolationIcon.vue";
import {
  chevronDown,
  chevronUp,
  cog,
  helpCircleOutline,
  logOutSharp,
  moonSharp,
  refresh,
  sunnySharp,
} from "ionicons/icons";
import parlerPay from "@/assets/parler/parler_pay.svg";
import { useAuthStore } from "@/stores/auth.js";

const props = defineProps({
  avatar: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["open", "openInvite", "close"]);
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const router = useRouter();
const route = useRoute();
const username = authStore.currentUser.username;
const settings = ref(false);
const remainingTime = ref("");

const toggleSettings = () => {
  settings.value = !settings.value;
};

const version = import.meta.env.VITE_APP_VERSION;

const date = import.meta.env.VITE_BUILD_DATE;

const isIOSPWA = computed(() => {
  return Boolean(window.webkit && window.webkit.messageHandlers);
});

const isAndroidPWA = computed(() => {
  return window.matchMedia("(display-mode: standalone)").matches;
});

const isApp = computed(() => {
  return isIOSPWA.value || isAndroidPWA.value;
});

const hasViolations = computed(() => {
  return globalStore.userViolations.length > 0;
});

const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("employee")
  );
});

const isCurrentUserAdmin = computed(() => {
  return (
    authStore.currentUser.badges && authStore.currentUser.badges.includes("administrator")
  );
});

onMounted(() => {
  if (!hasViolations.value) {
    globalStore.fetchUserViolations();
  }
  if (new Date(globalStore.timeoutExpiresAt) > new Date()) {
    startCountdown(new Date(globalStore.timeoutExpiresAt));
  }
});

onBeforeUnmount(() => {
  clearInterval(countdownInterval);
});

let countdownInterval = null;

function startCountdown(timeoutUntil) {
  countdownInterval = setInterval(() => {
    const now = new Date();
    const timeDifference = timeoutUntil - now;

    if (timeDifference <= 0) {
      clearInterval(countdownInterval);
      remainingTime.value = "00:00:00:00";
    } else {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);

      const formatTime = (time) => String(time).padStart(2, "0");

      remainingTime.value = `${days > 0 ? formatTime(days) + ":" : ""}${formatTime(
        hours
      )}:${formatTime(minutes)}:${formatTime(seconds)}`;
    }
  }, 1000);
}

const navigateToViolations = () => {
  if (urlIsViolations.value) {
    emit("close");
    return;
  }
  router.push("/violations");
};

let activeRoute;
if (route.path === `/${username}`) {
  activeRoute = "profile";
} else if (route.path === `/${username}/connections`) {
  activeRoute = "connections";
} else if (route.path === "/settings") {
  activeRoute = "settings";
} else {
  activeRoute = "home";
}

const forceRefresh = () => {
  // Clear cache
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.controller.postMessage({ action: "clearCache" });
  }

  // Reload the page
  window.location.reload(true);
};

const navigateToProfile = () => {
  if (urlIsProfile.value) {
    emit("close");
    return;
  }
  const encodedUsername = encodeURIComponent(username);

  router.push(`/${encodedUsername}`);
};

const navigateToHome = () => {
  if (urlIsHome.value) {
    emit("close");
    return;
  }
  router.push(`/feed`);
};

const navigateToConnections = () => {
  if (urlIsConnections.value) {
    emit("close");
    return;
  }
  const encodedUsername = encodeURIComponent(username);

  router.push(`/${encodedUsername}/connections`);
};

const navigateToSettings = () => {
  if (urlIsSettings.value) {
    emit("close");
    return;
  }
  router.push(`/settings`);
};

const openKnowledgeCenter = () => {
  window.open("https://parler-support.zendesk.com/", "_blank");
};

const openParlerStore = () => {
  window.open("https://shop.parler.com", "_blank");
};

const openParlerNews = () => {
  window.open("https://news.parler.com", "_blank");
};

const openParlerPay = () => {
  window.open("https://parlerpay.com", "_blank");
};

const openPlayTv = () => {
  window.open("https://playtv.parler.com", "_blank");
};

const urlIsProfile = computed(() => {
  return router.currentRoute.value.path === `/${username}`;
});

const urlIsConnections = computed(() => {
  return router.currentRoute.value.path === `/${username}/connections`;
});

const urlIsSettings = computed(() => {
  return router.currentRoute.value.path === `/settings`;
});

const urlIsHome = computed(() => {
  return router.currentRoute.value.path === `/feed`;
});

const urlIsViolations = computed(() => {
  return router.currentRoute.value.path === `/violations`;
});

const dark_mode = computed({
  get: () => globalStore.settings.dark_mode,
  set: (value) => {
    globalStore.updateSettings("dark_mode", value);
  },
});

function loginAdmin() {
  useAdminStore().loginAdmin();
}

function logout() {
  authStore.signOut(router);
  router.push("/login");
}
</script>
<style scoped>
ion-icon .active {
  color: var(--tertiary-color);
}

.primary {
  color: var(--primary-color);
}

.avatar {
  padding: 2px;
  /* Border width */
  border-radius: 50%;
}

.avatar.primary {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
}

.avatar img {
  display: block;
  border-radius: 50%;
}
</style>
